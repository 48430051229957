@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@font-face {
  font-family: 'NEXON Lv1 Gothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 10px;
}

body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 1.4rem;
  letter-spacing: -0.7px;
}
body.jp {
  font-family: "Noto Sans JP", sans-serif;
}

/**--------------- Scroll Bar ----------------**/
body::-webkit-scrollbar { width: 5px; }
body::-webkit-scrollbar-track { background-color: transparent; }
body::-webkit-scrollbar-thumb { background-color: #dcdcdc;	border-radius: 5px; }
div::-webkit-scrollbar { width: 5px; }
div::-webkit-scrollbar-track { background-color: transparent; }
div::-webkit-scrollbar-thumb { background-color: #dcdcdc;	border-radius: 5px; }
ul::-webkit-scrollbar { width: 5px; }
ul::-webkit-scrollbar-track { background-color: transparent; }
ul::-webkit-scrollbar-thumb { background-color: #dcdcdc;	border-radius: 5px; }


/**----------------- Common ------------------**/
.blind {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
}
.fc-red {
  color: #FF3F3F !important;
}

/**----------------- Input ------------------**/
input::placeholder,
textarea::placeholder {
  font-weight: normal;
  color: #b3b3b3;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: #fff;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
select, textarea {
  width: 100%;
  height: 3.65rem;
  padding: 0;
  font-size: 1.9rem;
  color: #333;
  border: 0;
  border-bottom: 1.5px solid #dbdbdb;
  background-color: #fff;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="text"]:read-only,
input[type="number"]:read-only {
  color: #aaa;
  background-color: #ededed;
}

input[type="text"]:disabled,
input[type="number"]:disabled {
  padding: 0 1rem;
  color: #aaa;
}

input[type="password"] {
  padding-right: 3rem;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-bottom: 1px solid #00d2b4;
}

input[type="text"] + label,
input[type="number"] + label,
input[type="password"] + label,
input[type="email"] + label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.7rem;
  color: #31C5AF;
  transform: translateY(0.5rem);
  visibility: hidden;
  opacity: 0;
}

input[type="text"]:focus + label,
input[type="number"]:focus + label,
input[type="password"]:focus + label,
input[type="email"]:focus + label {
  font-size: 1.3rem;
  transform: translateY(-1.6rem);
  transition: all 0.3s;
  visibility: visible;
  opacity: 1;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  -webkit-appearance:none;
  -moz-appearance:none; 
  -o-appearance:none; 
  appearance:none;
  visibility: hidden;
}

input[type="checkbox"] + label {
  display: inline-block;
  height: 2.4rem;
  padding-left: 3.25rem;
  font-size: 1.5rem;
  background-image: url("./assets/images/signup/form_checkbox.png");
  background-repeat: no-repeat;
  background-size: auto 2.4rem;
  cursor: pointer;
}

input[type="checkbox"]:checked + label {
  background-image: url("./assets/images/signup/form_checkbox_checked.png");
}

input[type="radio"] + label {
  display: inline-block;
  height: 2.4rem;
  padding-left: 3.25rem;
  font-size: 1.5rem;
  background-image: url("./assets/images/customer/form_radio.png");
  background-repeat: no-repeat;
  background-size: auto 2.4rem;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  background-image: url("./assets/images/customer/form_radio_checked.png");
}

select {
  -webkit-appearance:none;
  -moz-appearance:none; 
  -o-appearance:none; 
  appearance:none;
  background-image: url('./assets/images/signup/form_select.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto 2.2rem;
}


/**----------------- Signup ------------------**/
.signup .container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 5.2rem);
  padding-top: 3rem;
}

/* input-wrap select-wrap auth-wrap btn-wrap */
.signup .input-wrap {
  position: relative;
}

.signup .input-wrap .wrap {
  position : relative;
}

.signup .input-wrap .wrap button {
  position: absolute;
  top: 0;
  right: 0;
}

.signup .input-wrap .eye {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 100%;
}

.signup .input-wrap .eye.show {
  background-image: url('./assets/images/signup/icon_eye_show.png');
}

.signup .input-wrap .eye.hide {
  background-image: url('./assets/images/signup/icon_eye_hide.png');
}

.signup .input-wrap,
.signup .select-wrap,
.signup .auth-wrap {
  margin: 0 2.5rem 3.7rem 2.5rem;
}

.signup .input-wrap p,
.signup .select-wrap p {
  margin-top: 0.7rem;
  color: #777;
  line-height: 1.7rem;
}

.signup .input-wrap p span,
.signup .select-wrap p span {
  display: block;
}

.signup .input-wrap p span.error,
.signup .select-wrap p span.error {
  color: #ff3f3f;
}

.signup .input-wrap p span:nth-of-type(2),
.signup .select-wrap p span:nth-of-type(2) {
  margin-top: 0.5rem;
}

.signup .btn-wrap.fixed {
  margin-top: auto;
}

/* Button */
button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.signup .btn {
  width: 100%;
  text-align: center;
}

.signup .btn.round {
  border-radius: 7px;
}

.signup .btn.lg.round {
  border-radius: 2.5rem;
}

.signup .btn.lg {
  height: 5rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.signup .btn.md {
  height: 3.7rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.signup .btn.sm {
  width: auto;
  height: 3rem;
  padding: 0 0.8rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.signup .btn.green {
  color: #fff;
  background-color: #00d2b4;
}

.signup .btn.gray {
  color: #fff;
  background-color: #999;
}

.signup .btn.light-gray {
  color: #fff;
  background-color: #c0c0c0;
}

.signup .btn.yellow {
  color: #4d4d4d;
  background-color: #ffe26b;
}


/**----------------- Hwadap ------------------**/
.hwadap {
  
}

.hwadap .toast {
  visibility: hidden;
  opacity: 0;
  z-index: 3;
}

.hwadap .toast.active {
  visibility: visible;
  opacity: 1;
  transition: visibility opacity 0.2s;
}

mark {
  font-style: normal;
  font-weight: inherit;
}

/**----------------- Site ------------------**/
.site {
  padding-top: 15rem;
  color: #222222;
}

.site img {
  max-width: 100%;
}

.site .content-wrap {
  max-width: 85rem;
  margin: 0 auto;
  padding-bottom: 10rem;
}

.site button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.site .icon-new {
  margin-left: 0.4rem;
  width: 1.4rem;
}

.site .page-title {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1;
}

@media screen and (max-width: 800px) {
  .site {
    padding-top: 5.2rem;
  }
  .site .content-wrap {
    padding-bottom: 2rem; 
  }
  .site .page-title {
    display: none;
  }
}